import {
  Button,
  CardCheckable,
  CardContent,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import { useController } from 'react-hook-form';

import AddBar from 'src/Components/AddBar/AddBar.component';
import { ErrorMessage } from 'src/Components/ErrorMessage';
import { useFetchCandidateBusinesses } from 'src/Hooks/Candidates/useFetchCandidateBusinesses';

import styles from './BusinessesSkills.module.scss';
import { BusinessSkillsProps } from './BusinessesSkills.types';

export const BusinessesSkills = ({ control, candidateId, watch }: BusinessSkillsProps) => {
  const businessSkills = useFetchCandidateBusinesses(candidateId);

  const { field: businessSkillsField } = useController({ control, name: 'skills.businessSkills' });
  const qualifications = watch('qualifications');

  const filteredBusinessSkillsField = useMemo(
    () =>
      businessSkills.data
        ?.filter(business =>
          business.candidateQualifications?.some(
            qualif => qualif.qualificationId && qualifications.includes(qualif.qualificationId)
          )
        )
        .flatMap(business => business.candidateBusinessesSkills)
        .filter(Boolean)
        .map(skill => ({ id: skill.id ?? '', label: skill.label ?? '' }))
        .filter(skill => skill.id && skill.label),
    [businessSkills.data, qualifications]
  );

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>sélectionnez les compétences à mettre en avant</h3>
      {businessSkills.isError ? (
        <>
          <ErrorMessage message="Une erreur est survenue dans la récupération des compétences" />
          <div>
            <Button.Medium type="button" onClick={() => businessSkills.refetch()}>
              Réessayer
            </Button.Medium>
          </div>
        </>
      ) : !filteredBusinessSkillsField ? (
        new Array(5).fill(null).map((_, idx) => (
          <ContentLoader key={'load_' + idx} height="3rem" width="100%">
            <rect x="2%" y="20" rx="4" ry="4" width="100%" height="20" />
          </ContentLoader>
        ))
      ) : (
        <div className={styles.gridContainer}>
          {filteredBusinessSkillsField.map(skill => (
            <CardCheckable
              key={skill.id}
              color="beige"
              type="button"
              checked={businessSkillsField.value.includes(skill.id)}
              onChange={checked => {
                if (checked) {
                  businessSkillsField.onChange([...businessSkillsField.value, skill.id]);
                } else {
                  businessSkillsField.onChange(
                    businessSkillsField.value.filter(
                      qualificationId => qualificationId !== skill.id
                    )
                  );
                }
                businessSkillsField.onBlur();
              }}
            >
              <CardContent
                title={skill.label.charAt(0).toUpperCase() + skill.label.slice(1).toLowerCase()}
                titleClassName={styles.cardTitle}
              />
            </CardCheckable>
          ))}
        </div>
      )}
      <AddBar control={control} name="skills.behaviourSkills" />
    </div>
  );
};
