export enum QueryKeys {
  fetchCandidateSkills = 'fetchCandidateSkills',
  fetchCandidateR1 = 'fetchCandidateR1',
  fetchCandidateR2 = 'fetchCandidateR2',
  fetchCandidateDocuments = 'fetchCandidateDocuments',
  fetchCandidateDetails = 'fetchCandidateDetails',
  fetchCandidateDiplomas = 'fetchCandidateDiplomas',
  fetchCandidateAgencies = 'fetchCandidateAgencies',
  fetchCandidateBirthInfo = 'fetchCandidateBirthInfo',
  fetchCandidateETest = 'fetchCandidateETest',
  fetchCandidateTest = 'fetchCandidateTest',
  fetchDrivingLicenses = 'fetchDrivingLicenses',
  fetchCaces = 'fetchCaces',
  fetchElectricHabilitations = 'fetchElectricHabilitations',
  fetchLanguages = 'fetchLanguages',
  fetchMatchingCandidates = 'fetchMatchingCandidates',
  fetchCandidateConflicts = 'fetchCandidateConflicts',
  fetchPerimeter = 'fetchPerimeter',
  fetchPotentialPositions = 'fetchPotentialPositions',
  fetchService = 'fetchService',
  fetchConsultantInfo = 'fetchConsultantInfo',
  fetchR1ExperienceExchangeQuestions = 'fetchR1ExperienceExchangeQuestions',
  fetchR1StrengthQuestions = 'fetchR1StrengthQuestions',
  fetchCandidateLastContract = 'fetchCandidateLastContract',
  fetchCandidateInterviewsIdAndStatus = 'fetchCandidateInterviewsIdAndStatus',
  fetchR2ExchangeQuestions = 'fetchR2ExchangeQuestions',
  fetchR2ProjectionsQuestions = 'fetchR2ProjectionsQuestions',
  fetchR2ValidationQuestions = 'fetchR2ValidationQuestions',
  fetchCGC = 'fetchCGC',
  fetchInterviews = 'fetchInterviews',
  fetchSMICAmount = 'fetchSMICAmount',
  fetchJobs = 'fetchJobs',
  fetchJobQualifications = 'fetchJobQualifications',
  fetchLastYearCandidateContracts = 'fetchLastYearCandidateContracts',
  fetchCandidateExperiences = 'fetchCandidateExperiences',
  fetchCandidateExperiencesByCompany = 'fetchCandidateExperiencesByCompany',
  fetchMissionById = 'fetchMissionById',
  fetchInterviewsStatistics = 'fetchInterviewsStatistics',
  fetchCDIGoals = 'fetchCDIGoals',
  fetchCdiGoalsAdministrators = 'fetchCdiGoalsAdministrators',
  fetchConsultantsEstablishments = 'fetchConsultantsEstablishments',
  fetchOpenInterviewsStatistics = 'fetchOpenInterviewsStatistics',
  fetchSignedCDI = 'fetchSignedCDI',
  fetchInterviewsStatisticsPerAgency = 'fetchInterviewsStatisticsPerAgency',
  fetchActions = 'fetchActions',
  fetchCountries = 'fetchCountries',
  fetchCities = 'fetchCities',
  fetchNationalities = 'fetchNationalities',
  fetchDocumentData = 'fetchDocumentData',
  fetchDiplomasList = 'fetchDiplomasList',
  fetchDepartments = 'fetchDepartments',
  fetchQualificationsList = 'fetchQualificationsList',
  fetchSkillsList = 'fetchSkillsList',
  fetchOtherSkills = 'fetchOtherSkills',
  fetchAllHabilitations = 'fetchAllHabilitations',
  fetchCandidateBusinesses = 'fetchCandidateBusinesses',
  fetchCandidateDetailedBusiness = 'fetchCandidateDetailedBusiness',
  fetchQualificationSkillDetails = 'fetchQualificationSkillDetails',
}

export enum MutationKeys {
  updateCandidateR1 = 'updateCandidateR1',
  closeCandidateR1 = 'closeCandidateR1',
  closeCandidateR2 = 'closeCandidateR2',
  openPersonalResume = 'openPersonalResume',
  openOSMResume = 'openOSMResume',
  createCandidateR1 = 'createCandidateR1',
  createCandidateR2 = 'createCandidateR2',
  fetchCitiesAndDepartments = 'fetchCitiesAndDepartments',
  fetchCompany = 'fetchCompany',
  fetchDiplomas = 'fetchDiplomas',
  fetchHabilitations = 'fetchHabilitations',
  fetchHabilitationsAndDiploma = 'fetchHabilitationsAndDiploma',
  fetchLocations = 'fetchLocations',
  fetchQualifications = 'fetchQualifications',
  fetchSkills = 'fetchSkills',
  updateCandidateR2 = 'updateCandidateR2',
  deleteCandidateR1 = 'deleteCandidateR1',
  resetCandidateInterviews = 'resetCandidateInterviews',
  updateCandidateSkills = 'updateCandidateSkills',
  searchMissions = 'searchMissions',
  addCandidatesToMissions = 'addCandidatesToMissions',
  fetchMissionCriteria = 'fetchMissionCriteria',
  updateExternalExperience = 'updateExternalExperience',
  generateR1Pdf = 'generateR1Pdf',
  generateR2Pdf = 'generateR2Pdf',
  uploadInterviewGoals = 'uploadInterviewGoals',
  exportInterviewsData = 'exportInterviewsData',
  createAction = 'createAction',
  cancelAction = 'cancelAction',
  sendSMS = 'sendSMS',
  createCandidate = 'createCandidate',
  updateCandidate = 'updateCandidate',
  generateCandidateResume = 'generateCandidateResume',
}
