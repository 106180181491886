import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import { CandidateResumeBody, ResumeGeneratorService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useGenerateAndUploadCandidateResume = (
  id: string,
  extraOptions?: UseMutationOptions<Buffer, unknown, unknown>
) => {
  return useMutation(
    [MutationKeys.generateCandidateResume, id],
    async (body: CandidateResumeBody) => {
      const candidateResume =
        await ResumeGeneratorService.resumeGeneratorControllerGetCandidateResume(
          {
            candidateId: id,
            body,
          },
          { responseType: 'arraybuffer' }
        );
      return candidateResume;
    },
    extraOptions
  );
};
