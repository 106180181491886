import {
  Button,
  CardCheckable,
  CardContent,
  Loader,
  Message,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { useController } from 'react-hook-form';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import { MultiCheckboxCard } from 'src/Components/MultiCheckboxCard';
import { TextAreaCard } from 'src/Components/TextAreaCard';
import { useFetchCandidateBirthInfo } from 'src/Hooks/Candidates/useFetchCandidateBirthInfo';
import { useFetchCandidateDetails } from 'src/Hooks/Candidates/useFetchCandidateDetails';

import styles from './EmployeeData.module.scss';
import { EmployeeDataProps } from './EmployeeData.types';

export const EmployeeData = ({ candidateId, control }: EmployeeDataProps) => {
  const {
    data: candidateDetails,
    isError: candidateDetailsError,
    refetch: refetchCandidateDetails,
  } = useFetchCandidateDetails(candidateId);
  const {
    data: birthInfo,
    isError: birthInfoError,
    refetch: refetchBirthInfo,
  } = useFetchCandidateBirthInfo(candidateId);

  const isError = candidateDetailsError || birthInfoError;

  const { field, fieldState } = useController({ control, name: 'employee' });

  const birthDate = birthInfo?.birthDate && moment(birthInfo.birthDate);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <p>informations employé</p>
      </div>
      {isError ? (
        <>
          <ErrorMessage message="Une erreur est survenue dans la récupération des données" />
          <div>
            <Button.Medium
              type="button"
              onClick={() => {
                if (!candidateDetails) refetchCandidateDetails();
                if (!birthInfo) refetchBirthInfo();
              }}
            >
              Réessayer
            </Button.Medium>
          </div>
        </>
      ) : !candidateDetails ? (
        <Loader heightInRem={2} darkMode />
      ) : (
        <>
          <MultiCheckboxCard
            title={'identité'}
            data={[
              {
                subtitles: [`prénom : ${candidateDetails.firstName}`],
                selected: field.value.name,
                onSelectionChange: value => field.onChange({ ...field.value, name: value }),
              },
              {
                subtitles: [
                  `nom de famille : ${candidateDetails.name}`,
                  birthDate &&
                    `date de naissance et âge : ${birthDate.format('L')} (${moment().diff(
                      birthDate,
                      'years'
                    )} ans)`,
                ].filter(Boolean),
                selected: field.value.identity,
                onSelectionChange: value => field.onChange({ ...field.value, identity: value }),
              },
            ]}
            color={'beige'}
          />
          <CardCheckable
            color="beige"
            checked={field.value.address}
            onChange={value => field.onChange({ ...field.value, address: value })}
          >
            <CardContent
              title={'adresse'}
              supportingLines={[
                candidateDetails.address0,
                candidateDetails.address1,
                candidateDetails.address2,
                candidateDetails.address3,
                `${candidateDetails.zipCode} ${candidateDetails.city}`,
              ].filter(Boolean)}
            />
          </CardCheckable>
          <CardCheckable
            color="beige"
            checked={field.value.contactInfo}
            onChange={value => field.onChange({ ...field.value, contactInfo: value })}
          >
            <CardContent
              title={'contact'}
              supportingLines={[
                `Tel : ${candidateDetails.phone1} | ${candidateDetails.phone2}`,
                `email : ${candidateDetails.email}`,
              ].filter(Boolean)}
            />
          </CardCheckable>
          <CardCheckable
            color="beige"
            checked={field.value.availability}
            onChange={value => field.onChange({ ...field.value, availability: value })}
          >
            <CardContent
              title={'disponibilité'}
              supportingLines={[
                `début de disponibilité : ${moment(candidateDetails.availabilityForConsultantDate)
                  .add(1, 'day')
                  .format('L')}`,
              ]}
            />
          </CardCheckable>
          <TextAreaCard
            title="informations complémentaires"
            checked={field.value.other}
            onCheckedChange={value => field.onChange({ ...field.value, other: value })}
            text={field.value.otherValue}
            onTextChange={value => {
              const other = field.value.other || Boolean(value);
              field.onChange({ ...field.value, other, otherValue: value });
            }}
          />
          {fieldState.error && (
            <Message.Warning>
              <ul style={{ marginLeft: '1rem' }}>
                <li>{fieldState.error.message}</li>
              </ul>
            </Message.Warning>
          )}
        </>
      )}
    </div>
  );
};
