import {
  Button,
  ModalDeprecated,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { IllusRLLicense } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useState } from 'react';
import ContentLoader from 'react-content-loader';

import {
  LicenseA,
  LicenseA1,
  LicenseB,
  LicenseBE,
  LicenseC,
  LicenseC1,
  LicenseC1E,
  LicenseCE,
  LicenseD,
  LicenseD1,
  LicenseD1E,
  LicenseDE,
} from 'src/Assets';
import DrivingLicenseCard from 'src/Components/DrivingLicenseCard';
import { useFetchDrivingLicenses } from 'src/Hooks/DrivingLicense';

import styles from './DrivingLicenseModal.module.scss';
import { Props } from './DrivingLicenseModal.types';

const DrivingLicenseModal = ({ drivingLicenses, onDrivingLicenseChange, onClose }: Props) => {
  const [selectedDrivingLicenses, setSelectedDrivingLicenses] = useState(drivingLicenses);
  const { data, isLoading, isSuccess } = useFetchDrivingLicenses();
  const licenses = data ?? [];
  const iconMapping = {
    A: <LicenseA />,
    A1: <LicenseA1 />,
    B: <LicenseB />,
    C: <LicenseC />,
    D: <LicenseD />,
    EB: <LicenseBE />,
    EC: <LicenseCE />,
    ED: <LicenseDE />,
    C1: <LicenseC1 />,
    C1E: <LicenseC1E />,
    D1E: <LicenseD1E />,
    D1: <LicenseD1 />,
  };

  const licensesWithIcon = licenses.map(element => {
    return {
      licenseInformation: element,
      icon: iconMapping[element.identifier as keyof typeof iconMapping],
    };
  });
  return (
    <ModalDeprecated
      icon={<IllusRLLicense />}
      nested
      onClose={onClose}
      title="permis"
      open
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            setSelectedDrivingLicenses(drivingLicenses);
          }}
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <Button.Primary
          onClick={() => {
            onDrivingLicenseChange(selectedDrivingLicenses);
            onClose();
          }}
        >
          valider
        </Button.Primary>,
      ]}
    >
      <div
        className={styles.selectedNumberInfo}
      >{`${selectedDrivingLicenses.length} sélectionné(s)`}</div>
      <WithLightTitle title="sélectionner parmi la liste suivante">
        {isLoading &&
          licenses.map((_, idx) => (
            <ContentLoader key={idx} height="4.5rem" width="100%" uniqueKey="hours">
              <rect x="2%" y="10" rx="4" ry="4" width="50%" height="10" />
              <rect x="2%" y="30" rx="4" ry="4" width="80%" height="10" />
            </ContentLoader>
          ))}
        {isSuccess &&
          licensesWithIcon?.map(license => {
            const isSelected = selectedDrivingLicenses
              .map(drivingLicense => drivingLicense.identifier)
              .includes(license.licenseInformation.identifier);
            return (
              <DrivingLicenseCard
                key={license.licenseInformation.identifier}
                icon={license.icon}
                drivingLicense={license.licenseInformation}
                selected={isSelected}
                onClick={() => {
                  isSelected
                    ? setSelectedDrivingLicenses(
                        selectedDrivingLicenses.filter(
                          drivingLicense =>
                            drivingLicense.identifier !== license.licenseInformation.identifier
                        )
                      )
                    : setSelectedDrivingLicenses([
                        ...selectedDrivingLicenses,
                        license.licenseInformation,
                      ]);
                }}
              />
            );
          })}
      </WithLightTitle>
    </ModalDeprecated>
  );
};

export default DrivingLicenseModal;
