import { Checkbox, TextArea } from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import { useState } from 'react';

import styles from './TextAreaCard.module.scss';
import { TextAreaCardProps } from './TextAreaCard.types';

export const TextAreaCard = ({
  title,
  checked,
  onCheckedChange,
  text,
  onTextChange,
}: TextAreaCardProps) => {
  const [pressed, setPressed] = useState(false);

  return (
    <div
      role="checkbox"
      aria-checked={checked}
      aria-label={title}
      tabIndex={0}
      onClick={() => onCheckedChange(!checked)}
      onKeyDown={event => {
        if (event.key === ' ' || event.key === 'Enter') {
          setPressed(true);
        }
      }}
      onKeyUp={event => {
        if (event.key === ' ' || event.key === 'Enter') {
          setPressed(false);
          onCheckedChange(!checked);
        }
      }}
      onMouseDown={() => setPressed(true)}
      onMouseUp={() => setPressed(false)}
      className={classnames(styles.container, checked && styles.checked, pressed && styles.active)}
    >
      <div className={styles.checkboxContainer}>
        <Checkbox checked={checked} onChange={() => {}} />
      </div>
      <div className={styles.leftSide}>
        <div className={styles.title}>{title}</div>
        <TextArea
          onClick={event => event.stopPropagation()}
          onKeyDown={event => event.stopPropagation()}
          onKeyUp={event => event.stopPropagation()}
          onMouseDown={event => event.stopPropagation()}
          value={text}
          onChange={event => onTextChange(event.currentTarget.value)}
        />
      </div>
    </div>
  );
};
