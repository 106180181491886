import {
  AddButton,
  Button,
  Checkbox,
  DropDown,
  FetchButton,
  HorizontalCardFoldable,
  ModalDeprecated,
  TimePicker,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  IllusRLCalendar,
  PlusCircle,
  Trashcan,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import moment from 'moment';
import React, { useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';

import DatePickerInputWithDisplayMode from 'src/Components/WithDisplayMode/DatePickerInputWithDisplayMode';
import TextAreaWithLightTitleWithDisplayMode from 'src/Components/WithDisplayMode/TextAreaWithLightTitleWithDisplayMode';
import { useCancelAction } from 'src/Hooks/Actions/useCancelAction';
import { useCreateAction } from 'src/Hooks/Actions/useCreateAction';
import { useFetchActionsByIds } from 'src/Hooks/Actions/useFetchActionsByIds';
import { useFetchCandidateDetails } from 'src/Hooks/Candidates/useFetchCandidateDetails';
import { useFetchCandidateR1 } from 'src/Hooks/Candidates/useFetchCandidateR1';
import { useUpdateCandidateR1 } from 'src/Hooks/Candidates/useUpdateCandidateR1';
import { useSendSMS } from 'src/Hooks/SMS/useSendSms';
import { getBrandCode } from 'src/Redux/Perimeter/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import {
  Action,
  EnumCreateActionBodyType,
  EnumSendSMSBodyRecipientType,
  EnumSendSMSBodyTarget,
  R2PlanningContactChannel,
  R2PlanningEventStatus,
} from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';
import { pluralFormat } from 'src/Utils/pluralFormat';

import ChannelSelectionButton from './ChannelSelectionButton';
import styles from './R2PlanningModal.module.scss';
import { Props, r2PlanningSchema } from './R2PlanningModal.types';

const R2PlanningModal = ({ open, candidateId, close, agencyId, r1InterviewId }: Props) => {
  const [smsHasBeenSent, setSmsHasBeenSent] = useState(false);
  const [isContactChannelVisible, setIsContactChannelVisible] = useState(false);
  const [syncGoogleCal, setSyncGoogleCal] = useState(true);
  const { data: candidate } = useFetchCandidateDetails(candidateId);
  const candidateR1 = useFetchCandidateR1(r1InterviewId ?? '');
  const candidateR1Update = useUpdateCandidateR1(r1InterviewId ?? '');
  const createAction = useCreateAction();
  const brandCode = useSelector(getBrandCode);
  const fetchActions = useFetchActionsByIds(
    candidateR1.data?.r2PlanningEvents?.map(planningEvent => planningEvent.actionId ?? '') ?? [],
    brandCode,
    r1InterviewId ?? '',
    open
  );
  const cancelActionMutation = useCancelAction();

  const OSMActionsWithStatus: (Action & { r2PlanningEventStatus?: string })[] | undefined =
    fetchActions.data?.map(action => {
      const matchingEvent = candidateR1.data?.r2PlanningEvents?.find(
        event => event.actionId === action.actionId
      );

      if (matchingEvent) {
        return {
          ...action,
          r2PlanningEventStatus: matchingEvent.r2PlanningEventStatus,
        };
      }

      return action;
    });

  const sendSMSMutation = useSendSMS();
  const sendSMSFetchStatus = toFetchStatus(sendSMSMutation);

  const { control, watch, reset } = useFormWithZodResolver({
    schema: r2PlanningSchema,
    defaultValues: {
      r2PlanningContactChannel: undefined,
      exchangeSummary: '',
      r2AppointmentDate: undefined,
      r2AppointmentTimeStart: undefined,
      r2AppointmentTimeEnd: undefined,
      r2PlanningEventDate: new Date(),
      r2PlanningEventStatus: undefined,
    },
  });

  const r2PlanningContactChannel = useController({ name: 'r2PlanningContactChannel', control });
  const r2PlanningEventDate = useController({ name: 'r2PlanningEventDate', control });
  const r2PlanningEventStatus = useController({ name: 'r2PlanningEventStatus', control });
  const r2AppointmentDate = useController({ name: 'r2AppointmentDate', control });
  const r2AppointmentTimeStart = useController({ name: 'r2AppointmentTimeStart', control });
  const r2AppointmentTimeEnd = useController({ name: 'r2AppointmentTimeEnd', control });
  const exchangeSummary = watch('exchangeSummary');

  return (
    <ModalDeprecated
      title="planifier un R2"
      subtitle={`avec ${candidate?.firstName} ${candidate?.name} | ${agencyId}`}
      open={open}
      onOpen={() => {
        candidateR1.refetch();
        fetchActions.refetch();
      }}
      icon={<IllusRLCalendar />}
      onClose={() => {
        setSmsHasBeenSent(false);
        reset();
        close();
      }}
      footerActionsRight={[
        <Button.Secondary onClick={() => close()}>annuler</Button.Secondary>,
        <FetchButton
          title="valider"
          disabled={
            r2PlanningContactChannel.field.value === undefined ||
            exchangeSummary?.length === 0 ||
            r2PlanningEventDate.field.value === undefined ||
            (r2PlanningEventStatus.field.value === R2PlanningEventStatus['rendez-vous validé'] &&
              (r2AppointmentDate.field.value === undefined ||
                r2AppointmentTimeStart.field.value === undefined ||
                r2AppointmentTimeEnd.field.value === undefined ||
                r2AppointmentTimeStart.field.value > r2AppointmentTimeEnd.field.value)) ||
            r2PlanningEventStatus.field.value === undefined ||
            (r2PlanningContactChannel.field.value === R2PlanningContactChannel['contact par sms'] &&
              !smsHasBeenSent)
          }
          fetchStatus={toFetchStatus(createAction)}
          onSuccess={() => {
            reset();
            close();
          }}
          onClick={async () => {
            const currentDate = new Date();
            if (r2PlanningEventStatus.field.value === R2PlanningEventStatus['rendez-vous validé']) {
              const actions = await createAction.mutateAsync({
                targetId: candidateId,
                type:
                  r2PlanningContactChannel.field.value ===
                  R2PlanningContactChannel['contact par téléphone']
                    ? EnumCreateActionBodyType.ACTION_CANDIDATE_CALL
                    : EnumCreateActionBodyType.ACTION_CANDIDATE_SMS,
                startDate: new Date(
                  r2PlanningEventDate.field.value.setHours(currentDate.getHours())
                ),
                endDate: new Date(
                  r2PlanningEventDate.field.value.setHours(currentDate.getHours() + 1)
                ),
                agencyId: agencyId ?? '',
                syncGoogleCal,
                report: exchangeSummary,
                title: `Entretien R2 - ${candidate?.firstName} ${candidate?.name}`,
                r2AppointmentStartDate: new Date(
                  r2AppointmentDate.field.value.setHours(
                    parseInt(r2AppointmentTimeStart.field.value.slice(0, 2)),
                    parseInt(r2AppointmentTimeStart.field.value.slice(-2))
                  )
                ),
                r2AppointmentEndDate: new Date(
                  r2AppointmentDate.field.value.setHours(
                    parseInt(r2AppointmentTimeEnd.field.value.slice(0, 2)),
                    parseInt(r2AppointmentTimeEnd.field.value.slice(-2))
                  )
                ),
              });

              candidateR1Update.mutate({
                ...candidateR1.data,
                agencyName: agencyId ?? '',
                r2PlanningEvents: [
                  ...(candidateR1.data?.r2PlanningEvents ?? []),
                  {
                    actionId: actions[0]?.actionId,
                    r2PlanningContactChannel: r2PlanningContactChannel.field.value,
                    r2PlanningEventStatus: r2PlanningEventStatus.field.value,
                  },
                ],
              });
            } else {
              const newAction = await createAction.mutateAsync({
                targetId: candidateId,
                type:
                  r2PlanningContactChannel.field.value ===
                  R2PlanningContactChannel['contact par téléphone']
                    ? EnumCreateActionBodyType.ACTION_CANDIDATE_CALL
                    : EnumCreateActionBodyType.ACTION_CANDIDATE_SMS,
                startDate: new Date(
                  r2PlanningEventDate.field.value.setHours(currentDate.getHours())
                ),
                endDate: new Date(
                  r2PlanningEventDate.field.value.setHours(currentDate.getHours() + 1)
                ),
                agencyId: agencyId ?? '',
                report: exchangeSummary,
              });

              candidateR1Update.mutate({
                ...candidateR1.data,
                agencyName: agencyId ?? '',
                r2PlanningEvents: [
                  ...(candidateR1.data?.r2PlanningEvents ?? []),
                  {
                    actionId: newAction[0]?.actionId,
                    r2PlanningContactChannel: r2PlanningContactChannel.field.value,
                    r2PlanningEventStatus: r2PlanningEventStatus.field.value,
                  },
                ],
              });
            }
          }}
        />,
      ]}
    >
      <>
        <div className={styles.phoneAndEmail}>{candidate?.phone1}</div>
        <div className={styles.phoneAndEmail}>{candidate?.email}</div>
        {!isContactChannelVisible && (
          <AddButton
            className={styles.modalTrigger}
            icon={<PlusCircle className={styles.icon} />}
            text="ajouter une action"
            onClick={() => setIsContactChannelVisible(true)}
          />
        )}
        {isContactChannelVisible && (
          <ChannelSelectionButton
            disabled={smsHasBeenSent}
            value={r2PlanningContactChannel.field.value}
            onChange={r2PlanningContactChannel.field.onChange}
          />
        )}
        {[
          R2PlanningContactChannel['contact par téléphone'],
          R2PlanningContactChannel['contact par sms'],
        ].includes(r2PlanningContactChannel.field.value) && (
          <>
            <div className={styles.exchangeSummaryTextArea}>
              <TextAreaWithLightTitleWithDisplayMode
                name="exchangeSummary"
                title={
                  r2PlanningContactChannel.field.value ===
                  R2PlanningContactChannel['contact par téléphone']
                    ? `compte rendu de votre échange, créé le : ${moment().format(
                        'L'
                      )} - ${moment().format('HH')}h${moment().format('mm')}`
                    : 'votre message à envoyer par sms'
                }
                control={control}
                valueLength={exchangeSummary?.length}
                maxLength={
                  r2PlanningContactChannel.field.value ===
                  R2PlanningContactChannel['contact par téléphone']
                    ? 4000
                    : 130
                }
              />
            </div>
            {r2PlanningContactChannel.field.value ===
              R2PlanningContactChannel['contact par sms'] && (
              <FetchButton
                className={styles.sendSMS}
                title="envoyer le sms"
                disabled={exchangeSummary?.length === 0 || exchangeSummary === undefined}
                fetchStatus={sendSMSFetchStatus}
                onSuccess={() => {
                  setSmsHasBeenSent(true);
                  return;
                }}
                stayFulfilled
                onClick={async () => {
                  sendSMSMutation.mutate({
                    target: EnumSendSMSBodyTarget.CUSTOM,
                    message: exchangeSummary,
                    product: 'recrutLive',
                    recipientType: EnumSendSMSBodyRecipientType.TT,
                    object: 'entretien planification R2',
                    agencyId: agencyId,
                    recipientIds: [candidateId],
                  });
                }}
              />
            )}
            <div className={styles.dateAndStatusContainer}>
              <WithLightTitle title="date évènement" className={styles.datePicker}>
                <DatePickerInputWithDisplayMode
                  date={r2PlanningEventDate.field.value}
                  onSelectDate={r2PlanningEventDate.field.onChange}
                  className={styles.datePicker}
                  // Can't create an event that happened more than a week ago or more than three months from now
                  minDate={new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)}
                  maxDate={new Date(Date.now() + 90 * 24 * 60 * 60 * 1000)}
                />
              </WithLightTitle>
              <WithLightTitle title="statut" className={styles.dropDown}>
                <DropDown
                  className={styles.dropDown}
                  items={Object.values(R2PlanningEventStatus)}
                  placeholder="statut"
                  selectedItem={r2PlanningEventStatus.field.value}
                  onSelectItem={(item?: R2PlanningEventStatus) => {
                    return r2PlanningEventStatus.field.onChange(item);
                  }}
                  keyValueExtractor={(item: R2PlanningEventStatus) => {
                    return { key: item, value: item };
                  }}
                />
              </WithLightTitle>
            </div>
            {r2PlanningEventStatus.field.value === R2PlanningEventStatus['rendez-vous validé'] && (
              <div className={styles.appointmentTimeContainer}>
                <WithLightTitle title="date du rendez-vous" className={styles.datePicker}>
                  <DatePickerInputWithDisplayMode
                    date={r2AppointmentDate.field.value}
                    onSelectDate={r2AppointmentDate.field.onChange}
                    className={styles.datePicker}
                    minDate={new Date(r2PlanningEventDate.field.value)}
                    maxDate={new Date(Date.now() + 90 * 24 * 60 * 60 * 1000)}
                  />
                </WithLightTitle>
                <WithLightTitle title="horaires" className={styles.appointmentTimePickerContainer}>
                  <TimePicker
                    value={r2AppointmentTimeStart.field.value}
                    className={styles.appointmentTimeLeft}
                    onChange={event => {
                      r2AppointmentTimeStart.field.onChange(
                        (event.target as HTMLInputElement).value
                      );
                    }}
                  />
                  <TimePicker
                    value={r2AppointmentTimeEnd.field.value}
                    className={styles.appointmentTimeRight}
                    onChange={event => {
                      r2AppointmentTimeEnd.field.onChange((event.target as HTMLInputElement).value);
                    }}
                  />
                </WithLightTitle>
                <div className={styles.syncGoogleCal}>
                  <Checkbox
                    label={'créer un évènement google'}
                    onChange={event => setSyncGoogleCal(event.target.checked)}
                    checked={syncGoogleCal}
                  />
                </div>
              </div>
            )}
          </>
        )}
        {toFetchStatus(fetchActions) === FETCH_STATUS.FULFILLED &&
          candidateR1.data?.r2PlanningEvents !== undefined &&
          candidateR1.data?.r2PlanningEvents.length > 0 && (
            <WithLightTitle
              title={
                fetchActions.data !== undefined && fetchActions.data.length > 0
                  ? pluralFormat(fetchActions.data.length, 'action')
                  : ''
              }
              className={styles.planningEventsList}
            >
              {OSMActionsWithStatus?.reverse().map((planningEvent, index) => (
                <div key={index} className={styles.planningEventContainer}>
                  <HorizontalCardFoldable
                    containerClassName={styles.horizontalCard}
                    overLabel={`le ${moment({
                      ...planningEvent.startDate,
                      month: (planningEvent?.startDate?.month ?? 1) - 1,
                    }).format('L')}`}
                    label={planningEvent.r2PlanningEventStatus ?? ''}
                    rightComponent={
                      index === 0 ? (
                        <Button.Tertiary className={styles.iconButton}>
                          <Trashcan
                            onClick={() => {
                              cancelActionMutation.mutate({
                                actionId: planningEvent.actionId ?? '',
                              });
                              const updatedR2PlanningEvents =
                                candidateR1.data?.r2PlanningEvents?.filter(
                                  event => event.actionId !== planningEvent.actionId
                                ) ?? [];
                              candidateR1Update.mutate({
                                ...candidateR1.data,
                                agencyName: agencyId ?? '',
                                r2PlanningEvents: updatedR2PlanningEvents,
                              });
                            }}
                            className={styles.trashCan}
                          />
                        </Button.Tertiary>
                      ) : null
                    }
                  >
                    <div className={styles.exchangeSummaryMemo}>{planningEvent.report}</div>
                  </HorizontalCardFoldable>
                </div>
              ))}
            </WithLightTitle>
          )}
        {toFetchStatus(fetchActions) === FETCH_STATUS.PENDING &&
          candidateR1.data?.r2PlanningEvents !== undefined &&
          candidateR1.data.r2PlanningEvents.length > 0 && (
            <ContentLoader height="5rem" width="100%" uniqueKey="r2PlanningActions">
              <rect y="10" width="98%" height="48" />
            </ContentLoader>
          )}
      </>
    </ModalDeprecated>
  );
};

export default R2PlanningModal;
