import {
  Button,
  Drawer,
  Stepper,
  toast,
} from '@randstad-lean-mobile-factory/react-components-core';
import { DownloadLine } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { UseFormHandleSubmit } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { z } from 'zod';

import { useGenerateAndUploadCandidateResume } from 'src/Hooks/Candidates/useGenerateAndUploadCandidateResume';
import { getBrandCode } from 'src/Redux/Perimeter/Selectors';
import { openFile } from 'src/Utils/files';

import ResumeGeneratorConfirmationModal from '../Modals/ResumeGeneratorConfirmationModal/ResumeGeneratorConfirmationModal.component';
import ResumeGeneratorExitModal from '../Modals/ResumeGeneratorExitModal';
import ResumeGeneratorValidationModal from '../Modals/ResumeGeneratorValidationModal/ResumeGeneratorValidationModal.component';

import { BusinessesSkills } from './BusinessSkills';
import styles from './CandidateResume.module.scss';
import { FormStep, formSchema, formSteps } from './CandidateResume.schema';
import { Degrees } from './Degrees';
import { Description } from './Description';
import { EmployeeData } from './EmployeeData';
import { Experiences } from './Experiences';
import Overview from './Overview/Overview.component';
import { Qualifications } from './Qualifications';

const CandidateResume = () => {
  const history = useHistory();
  const { candidateId } = useParams<{ candidateId: string }>();
  const brandCode = useSelector(getBrandCode);

  const generateCandidateResume = useGenerateAndUploadCandidateResume(candidateId);

  const { control, trigger, handleSubmit, getFieldState, getValues, watch } =
    useFormWithZodResolver({
      schema: formSchema,
      defaultValues: {
        qualifications: [],
        skills: {
          businessSkills: [],
          behaviourSkills: [],
        },
        experiences: {
          title: '',
          experiences: [],
        },
        degrees: {
          diplomas: [],
          tests: [],
          habilitations: [],
          drivingLicences: [],
        },
        employee: {
          name: false,
          identity: false,
          address: false,
          contactInfo: false,
          availability: false,
          other: false,
          otherValue: '',
        },
        description: '',
      },
    });

  const [formStep, setFormStep] = useState<FormStep>('qualifications');
  const stepperStep = useMemo(() => {
    if (['skills', 'experiences', 'degrees'].includes(formStep)) return 'professionalData';
    return formStep;
  }, [formStep]);
  const professionalData = useMemo(() => {
    switch (formStep) {
      case 'skills':
        return 'données professionnelles 1/3';
      case 'experiences':
        return 'données professionnelles 2/3';
      case 'degrees':
        return 'données professionnelles 3/3';
      default:
        return 'données professionnelles';
    }
  }, [formStep]);

  const employee = watch('employee');
  const qualifications = watch('qualifications');

  const isCvAnonymous = useMemo(
    () => !employee.address && !employee.contactInfo && !employee.identity && !employee.name,
    [employee]
  );

  const osmoseDocumentName = useMemo(
    () =>
      [...qualifications, moment().format('L'), isCvAnonymous ? 'A' : undefined]
        .filter(Boolean)
        .join('_'),
    [isCvAnonymous, qualifications]
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isValidationModalOpen, setIsValidationModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const downloadResume = useCallback(() => {
    handleSubmit(
      values =>
        generateCandidateResume.mutate(
          {
            qualifications: values.qualifications,
            businessesSkills: values.skills.businessSkills,
            behaviourSkills: values.skills.behaviourSkills,
            experiences: values.experiences.experiences,
            experienceTitle: values.experiences.title,
            diplomas: values.degrees.diplomas,
            tests: values.degrees.tests,
            habilitations: values.degrees.habilitations,
            drivingLicences: values.degrees.drivingLicences,
            employeeData: values.employee,
            description: values.description,
            resumeType: values.resumeType,
            brandCode: brandCode,
            osmoseTitle: osmoseDocumentName,
          },
          {
            onSuccess: resultBuffer => {
              openFile(resultBuffer, 'application/pdf');
              //TODO : saving the pdf in osmose
              setIsValidationModalOpen(true);
            },
            onError: error => {
              console.error(error);
              toast.error(
                "une erreur s'est produite lors de la génération de la synthèse candidat"
              );
            },
          }
        ),
      console.error
    )();
  }, [brandCode, generateCandidateResume, handleSubmit, osmoseDocumentName]);

  const onSubmit = useCallback(() => {
    if (!isCvAnonymous) {
      setIsConfirmationModalOpen(true);
    } else {
      downloadResume();
    }
  }, [downloadResume, isCvAnonymous]);

  const goToStep = useCallback(
    async (nextStep: FormStep) => {
      const prevStepIdx = formSteps.indexOf(formStep);
      const nextStepIdx = formSteps.indexOf(nextStep);
      if (nextStepIdx < prevStepIdx) return setFormStep(nextStep);
      for (const prevStep of formSteps.slice(0, nextStepIdx)) {
        const isSuccess = await trigger(prevStep);
        if (!isSuccess) return;
      }
      setFormStep(nextStep);
    },
    [formStep, trigger]
  );
  const setStepperStep = useCallback(
    (stepperStep: string) => {
      if (stepperStep !== 'professionalData') goToStep(stepperStep as FormStep);
      else goToStep('skills');
    },
    [goToStep]
  );
  const goToPrev = useCallback(() => {
    const prevStepIdx = formSteps.indexOf(formStep);
    if (prevStepIdx > 0) setFormStep(formSteps[prevStepIdx - 1]);
  }, [setFormStep, formStep]);
  const goToNext = useCallback(() => {
    const prevStepIdx = formSteps.indexOf(formStep);
    if (prevStepIdx < formSteps.length - 1) setFormStep(formSteps[prevStepIdx + 1]);
    else handleSubmit(onSubmit, console.error)();
  }, [setFormStep, handleSubmit, onSubmit, formStep]);

  const handleNext = useMemo(
    (): UseFormHandleSubmit<z.infer<typeof formSchema>> =>
      (successCallback, errorCallback) =>
      async event => {
        event?.preventDefault();
        trigger(formStep).then(isSuccess => {
          if (isSuccess) successCallback(getValues(), event);
          else errorCallback?.({ [formStep]: getFieldState(formStep).error }, event);
        });
      },
    [trigger, formStep, getValues, getFieldState]
  );

  const close = () => {
    setIsModalOpen(true);
  };

  const currentStep = useMemo(() => {
    switch (formStep) {
      case 'qualifications': {
        return <Qualifications candidateId={candidateId} control={control} />;
      }
      case 'skills': {
        return <BusinessesSkills candidateId={candidateId} control={control} watch={watch} />;
      }
      case 'experiences': {
        return <Experiences candidateId={candidateId} control={control} watch={watch} />;
      }
      case 'degrees': {
        return <Degrees candidateId={candidateId} control={control} />;
      }
      case 'employee': {
        return <EmployeeData candidateId={candidateId} control={control} />;
      }
      case 'description': {
        return <Description control={control} />;
      }
      case 'resumeType': {
        return <Overview control={control} />;
      }
    }
  }, [candidateId, control, formStep, watch]);

  return (
    <>
      <Drawer onClose={close} open>
        <div className={styles.header}>
          <h2 className={styles.title}>synthèse candidat</h2>
          <Stepper
            fields={[
              'qualifications',
              professionalData,
              'informations employé',
              'description',
              'aperçu',
            ]}
            selectedkey={[
              'qualifications',
              'professionalData',
              'employee',
              'description',
              'resumeType',
            ]}
            selected={stepperStep}
            onSelectionChange={stepperStep => setStepperStep(stepperStep)}
          />
        </div>
        <form
          style={{ flex: '1 1', minHeight: '0', display: 'flex', flexDirection: 'column' }}
          onSubmit={handleNext(goToNext, console.error)}
        >
          <div style={{ flex: '1 1', overflow: 'auto' }}>{currentStep}</div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
            <Button.Secondary
              type="button"
              onClick={goToPrev}
              disabled={formStep === 'qualifications'}
              text={
                formStep === 'resumeType' ? 'modifier les informations de la synthèse' : 'précédent'
              }
            />
            <Button
              type="submit"
              leftIcon={formStep === 'resumeType' ? <DownloadLine /> : undefined}
              text={formStep === 'resumeType' ? 'télécharger' : 'suivant'}
              disabled={generateCandidateResume.isLoading}
            />
          </div>
        </form>
      </Drawer>

      <ResumeGeneratorExitModal
        close={() => {
          history.push({
            pathname: '/results',
          });
        }}
        open={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <ResumeGeneratorValidationModal
        close={() => {
          setIsValidationModalOpen(false);
          close();
        }}
        open={isValidationModalOpen}
        documentName={osmoseDocumentName}
      />
      <ResumeGeneratorConfirmationModal
        open={isConfirmationModalOpen}
        setOpen={setIsConfirmationModalOpen}
        downloadResume={downloadResume}
      />
    </>
  );
};

export default CandidateResume;
