import { CardRadio, Message } from '@randstad-lean-mobile-factory/react-components-core';
import { useController } from 'react-hook-form';

import { PremiumResume, SimplifiedResume } from 'src/Assets';

import styles from './Overview.module.scss';
import { OverviewProps } from './Overview.types';

const Overview = ({ control }: OverviewProps) => {
  const { field, fieldState } = useController({ control, name: 'resumeType' });

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>aperçu de la synthèse</div>
      {fieldState.error && (
        <Message.Warning>
          <ul style={{ marginLeft: '1rem' }}>
            <li>{fieldState.error.message}</li>
          </ul>
        </Message.Warning>
      )}
      <div className={styles.cardContainer}>
        <CardRadio
          color="white"
          checked={field.value === 'simplified'}
          onChange={selected => {
            if (selected) field.onChange('simplified');
          }}
          className={styles.card}
        >
          <SimplifiedResume />
        </CardRadio>
        <CardRadio
          color="white"
          checked={field.value === 'premium'}
          onChange={selected => {
            if (selected) field.onChange('premium');
          }}
          className={styles.card}
        >
          <PremiumResume />
        </CardRadio>
      </div>
    </div>
  );
};

export default Overview;
