import { z } from 'zod';

import { EnumCandidateResumeBodyResumeType } from 'src/Services/API';

export const formSchema = z.object({
  qualifications: z
    .array(z.string())
    .min(1, { message: 'il faut choisir au moins une qualification' }),
  skills: z.object({
    businessSkills: z.array(z.string()),
    behaviourSkills: z.array(z.string()),
  }),
  experiences: z.object({
    title: z.string(),
    experiences: z.array(
      z.object({
        label: z.string(),
        companyName: z.string(),
        qualifications: z.array(z.string()),
        firstDate: z.string(),
        nbContracts: z.string(),
        duration: z.string(),
      })
    ),
  }),
  degrees: z.object({
    diplomas: z.array(z.string()),
    tests: z.array(z.string()),
    habilitations: z.array(z.string()),
    drivingLicences: z.array(z.string()),
  }),
  employee: z
    .object({
      name: z.boolean(),
      identity: z.boolean(),
      address: z.boolean(),
      contactInfo: z.boolean(),
      availability: z.boolean(),
      other: z.boolean(),
      otherValue: z.string(),
    })
    .refine(employee => !(employee.other && !employee.otherValue), {
      message: 'veuillez indiquer les informations complémentaires à ajouter à la synthèse',
    }),
  description: z.string(),
  resumeType: z.nativeEnum(EnumCandidateResumeBodyResumeType, {
    invalid_type_error: 'veuillez choisir un type de synthèse',
    required_error: 'veuillez choisir un type de synthèse',
  }),
});

export type FormStep = keyof z.infer<typeof formSchema>;

export const formSteps = [
  'qualifications',
  'skills',
  'experiences',
  'degrees',
  'employee',
  'description',
  'resumeType',
] as FormStep[];
