import {
  Button,
  CardCheckable,
  CardContent,
  Checkbox,
  SegmentedControl,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { Fragment, useEffect, useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useController } from 'react-hook-form';
import { z } from 'zod';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import {
  backgroundMenuLabel,
  EXPERIENCE_TYPE,
  SORT_OPTIONS,
  SORT_ORDER,
} from 'src/Containers/CandidateFile/History/Background/Background.types';
import SortOptions from 'src/Containers/CandidateFile/History/Background/SortOptions';
import {
  getSubtitles,
  groupExperiences,
  totalDurationInHours,
} from 'src/Containers/CandidateFile/History/Background/utils';
import { useFetchCandidateExperiencesByCompany } from 'src/Hooks/Candidates/useFetchCandidateExperiencesByCompany';
import { useFetchCandidateSkills } from 'src/Hooks/Candidates/useFetchCandidateSkills';
import { pluralFormat } from 'src/Utils/pluralFormat';

import { formSchema } from '../CandidateResume.schema';

import styles from './Experiences.module.scss';
import { ExperiencesProps } from './Experiences.types';

export const Experiences = ({ candidateId, control, watch }: ExperiencesProps) => {
  const selectedQualificationIds = watch('qualifications');

  const skillsQuery = useFetchCandidateSkills(candidateId, { retry: false });
  const experiencesQuery = useFetchCandidateExperiencesByCompany(candidateId, true, {
    retry: false,
  });
  const isError = skillsQuery.isError || experiencesQuery.isError;

  const selectedQualifications = selectedQualificationIds
    .map(qualificationId =>
      skillsQuery.data?.qualifications?.find(qualification => qualification.id === qualificationId)
    )
    .filter(Boolean);

  const [openToAll, setOpenToAll] = useState<boolean>(true);

  const { field: experiencesField } = useController({
    name: 'experiences.experiences',
    control,
  });
  const { field: experiencesTitleField } = useController({
    name: 'experiences.title',
    control,
  });

  const [experienceType, setExperienceType] = useState<EXPERIENCE_TYPE>(
    EXPERIENCE_TYPE.INTERNAL_EXPERIENCES
  );
  const [sortOption, setSortOption] = useState<SORT_OPTIONS>(SORT_OPTIONS.YEAR);
  const [sortOrder, setSortOrder] = useState<SORT_ORDER>(SORT_ORDER.DESCENDING);

  const experiences = useMemo(() => {
    const experiences = openToAll
      ? experiencesQuery.data
      : experiencesQuery.data && {
          ...experiencesQuery.data,
          experiences: experiencesQuery.data.experiences.filter(
            el =>
              el.experiencesByCompany.externalExperiences.some(extExp =>
                selectedQualificationIds?.includes(extExp.qualification?.id || '')
              ) ||
              el.experiencesByCompany.internalExperiences.some(intExp =>
                selectedQualificationIds?.includes(intExp.qualification?.id || '')
              )
          ),
        };
    return (
      experiences &&
      groupExperiences(
        experiences,
        experienceType === EXPERIENCE_TYPE.INTERNAL_EXPERIENCES,
        sortOption,
        sortOrder
      )
    );
  }, [
    experiencesQuery.data,
    experienceType,
    openToAll,
    selectedQualificationIds,
    sortOption,
    sortOrder,
  ]);

  useEffect(() => {
    const totalHours =
      experiences?.groupedExperiences.reduce(
        (acc, group) => acc + totalDurationInHours(group.experiences),
        0
      ) ?? 0;
    const allDates =
      experiences?.groupedExperiences
        .flatMap(group => group.experiences.flatMap(exp => [exp.startDate, exp.endDate]))
        .filter(Boolean)
        .sort() ?? [];
    const firstDate = new Date(allDates[0]);
    const lastDate = new Date(allDates.at(0) ?? 0);

    if (allDates.length > 0) {
      experiencesTitleField.onChange(
        experienceType === EXPERIENCE_TYPE.INTERNAL_EXPERIENCES
          ? `${totalHours} heures totales via randstad de ${firstDate.getFullYear()} à 
        ${lastDate.getFullYear()}`
          : `expérience externe de ${firstDate.getFullYear()} à 
          ${lastDate.getFullYear()}`
      );
    } else {
      experiencesTitleField.onChange(
        experienceType === EXPERIENCE_TYPE.INTERNAL_EXPERIENCES
          ? "pas d'expérience via randstad"
          : "pas d'expérience externe"
      );
    }
  }, [experienceType, experiences, experiencesTitleField]);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        {`sélectionnez les expériences à mettre en avant sur ${
          openToAll
            ? `l'ensemble des qualifications du talent`
            : selectedQualifications.length > 1
            ? 'les qualifications :'
            : 'la qualification :'
        }
      `}
        {!openToAll && (
          <div className={styles.qualifications}>
            {selectedQualifications
              .map(qualification =>
                qualification.label
                  ? qualification.label.charAt(0) + qualification.label.slice(1).toLowerCase()
                  : ''
              )
              .filter(Boolean)
              .join(', ')}
          </div>
        )}
      </div>
      <SegmentedControl
        controls={Object.values(EXPERIENCE_TYPE)}
        selected={experienceType}
        getValue={item => backgroundMenuLabel[item]}
        onSelectionChange={item => {
          setExperienceType(item);
        }}
        className={styles.segmentedControl}
        labelClassName={styles.segmentedLabels}
      />
      <div className={styles.optionsContainer}>
        <Checkbox
          checked={openToAll}
          onChange={() => setOpenToAll(!openToAll)}
          label={`élargir à l'ensemble des expériences du talent | ${experiencesTitleField.value}`}
        />
        <SortOptions
          selectedSortOption={sortOption}
          setSelectedSortOption={setSortOption}
          selectedSortOrder={sortOrder}
          setSelectedSortOrder={setSortOrder}
        />
      </div>
      <div className={styles.experiencesContainer}>
        {isError ? (
          <>
            <ErrorMessage message="Une erreur est survenue dans la récupération des données" />
            <div>
              <Button.Medium
                type="button"
                onClick={() => {
                  if (!experiencesQuery.data) experiencesQuery.refetch();
                  if (!skillsQuery.data) skillsQuery.refetch();
                }}
              >
                Réessayer
              </Button.Medium>
            </div>
          </>
        ) : !experiences ? (
          <>
            <ContentLoader height="2.5rem" width="100%">
              <rect x="2%" y="10" rx="4" ry="4" width="100%" height="200" />
            </ContentLoader>
            {new Array(3).fill(null).map((_, idx) => (
              <ContentLoader key={idx} height="5.75rem" width="100%">
                <rect x="2%" y="10" rx="4" ry="4" width="100%" height="200" />
              </ContentLoader>
            ))}
          </>
        ) : (
          experiences.labels?.map(label => (
            <Fragment key={label}>
              {experienceType === EXPERIENCE_TYPE.INTERNAL_EXPERIENCES &&
                sortOption !== SORT_OPTIONS.HOURS && (
                  <div className={styles.groupTitle} key={label}>
                    {label}
                  </div>
                )}
              {experiences?.groupedExperiences
                .filter(experience => experience.label === label)
                .map(experience => (
                  <CardCheckable
                    key={`${experience.companyName} - ${experience.label}`}
                    color="beige"
                    checked={experiencesField.value.some(
                      exp =>
                        exp.label === experience.label && exp.companyName === experience.companyName
                    )}
                    onChange={checked => {
                      if (checked) {
                        if (
                          !experiencesField.value.some(
                            el =>
                              el.label === experience.label &&
                              el.companyName === experience.companyName
                          )
                        ) {
                          const uniqueLabels: string[] = [];
                          const labelSet = new Set();
                          experience.experiences.forEach(q => {
                            if (
                              q.qualification &&
                              q.qualification.label &&
                              !labelSet.has(q.qualification.label.toLowerCase())
                            ) {
                              labelSet.add(q.qualification.label.toLowerCase());
                              uniqueLabels.push(q.qualification.label.toLowerCase());
                            }
                          });
                          const totalDurationInMonths = Math.floor(
                            totalDurationInHours(experience.experiences) / (24 * 30)
                          );
                          const totalDurationInWeeks = Math.floor(
                            totalDurationInHours(experience.experiences) / (24 * 7)
                          );
                          const totalDurationInDays = Math.floor(
                            totalDurationInHours(experience.experiences) / 24
                          );
                          const item: z.infer<
                            typeof formSchema
                          >['experiences']['experiences'][number] = {
                            qualifications: uniqueLabels,
                            label: experience.label,
                            companyName: experience.companyName,
                            firstDate:
                              experience.experiences.length > 0
                                ? moment(experience.experiences[0].startDate).format('L')
                                : '',
                            nbContracts: pluralFormat(experience.experiences.length, 'contrat'),
                            duration: `${
                              totalDurationInMonths > 0
                                ? `${totalDurationInMonths} mois`
                                : totalDurationInWeeks > 0
                                ? `${pluralFormat(totalDurationInWeeks, 'semaine')}`
                                : totalDurationInDays > 0
                                ? `${pluralFormat(totalDurationInDays, 'jour')} `
                                : `${pluralFormat(
                                    Math.floor(totalDurationInHours(experience.experiences)),
                                    'heure'
                                  )}`
                            }`,
                          };
                          experiencesField.onChange([...experiencesField.value, item]);
                        }
                      } else {
                        experiencesField.onChange(
                          experiencesField.value.filter(
                            el =>
                              !(
                                el.label === experience.label &&
                                el.companyName === experience.companyName
                              )
                          )
                        );
                      }
                    }}
                  >
                    <CardContent
                      title={experience.companyName}
                      supportingLines={getSubtitles(experience.experiences)}
                    />
                  </CardCheckable>
                ))}
            </Fragment>
          ))
        )}
      </div>
    </div>
  );
};
