import moment from 'moment';

import { CandidateAllExperiencesByCompany, CandidateExperience } from 'src/Services/API';
import { pluralFormat } from 'src/Utils/pluralFormat';

import { EXPERIENCE_TYPE, GroupedExperience, SORT_OPTIONS, SORT_ORDER } from './Background.types';

export const getQualifications = (experiences: CandidateExperience[]): string => {
  const encounteredLabels: Set<string> = new Set();
  const uniqueLabels: string[] = [];

  for (const experience of experiences) {
    const label = experience?.qualification?.label;
    if (label && !encounteredLabels.has(label)) {
      encounteredLabels.add(label);
      uniqueLabels.push(label);
      if (uniqueLabels.length === 3) {
        break;
      }
    }
  }

  return uniqueLabels.join(', ');
};

export const getSubtitles = (experiences: CandidateExperience[]): string[] => {
  experiences.sort((a, b) => {
    const dateA = new Date(a?.startDate ?? '');
    const dateB = new Date(b?.startDate ?? '');
    return (dateA.getTime() ?? 0) - (dateB.getTime() ?? 0);
  });
  const firstDate = experiences.length > 0 ? moment(experiences[0].startDate).format('L') : '';
  const totalDurationInMonths = Math.floor(totalDurationInHours(experiences) / (24 * 30));
  const totalDurationInWeeks = Math.floor(totalDurationInHours(experiences) / (24 * 7));
  const totalDurationInDays = Math.floor(totalDurationInHours(experiences) / 24);

  return [
    getQualifications(experiences),
    [
      firstDate,
      `${pluralFormat(experiences.length, 'contrat')}`,
      `${
        totalDurationInMonths > 0
          ? `${totalDurationInMonths} mois`
          : totalDurationInWeeks > 0
          ? `${pluralFormat(totalDurationInWeeks, 'semaine')}`
          : totalDurationInDays > 0
          ? `${pluralFormat(totalDurationInDays, 'jour')} `
          : `${pluralFormat(Math.floor(totalDurationInHours(experiences)), 'heure')}`
      }`,
    ].join(' | '),
  ];
};

export const groupByName = (experiences: CandidateExperience[]): string => {
  return experiences[0]?.companyName?.charAt(0)?.toLowerCase() ?? '-';
};

export const groupByYears = (experiences: CandidateExperience[]): number => {
  const startYear = new Date(experiences[0]?.startDate ?? '')?.getFullYear();
  return startYear;
};

export const totalDurationInHours = (experiences: CandidateExperience[]): number => {
  const totalDuration = experiences.reduce(
    (cum: number, cur: CandidateExperience) =>
      cum +
      ((new Date(cur?.endDate ?? '')?.getTime() ?? 0) -
        (new Date(cur?.startDate ?? '')?.getTime() ?? 0)),
    0
  );
  const totalDurationInHours = Math.floor(totalDuration / (1000 * 60 * 60));
  return totalDurationInHours;
};

export const groupByFunctions = {
  [SORT_OPTIONS.NAME]: groupByName,
  [SORT_OPTIONS.YEAR]: groupByYears,
  [SORT_OPTIONS.HOURS]: totalDurationInHours,
};

export const groupExperiences = (
  experiences: CandidateAllExperiencesByCompany,
  isInternalExperiences: boolean,
  sortOption: SORT_OPTIONS,
  sortOrder: SORT_ORDER = SORT_ORDER.ASCENDING
): { labels: string[]; groupedExperiences: GroupedExperience[] } => {
  const groupBy = groupByFunctions[sortOption];

  const groupedExperiences: GroupedExperience[] = [];
  const labels: string[] = [];
  for (const companyExperiences of experiences.experiences.map(exp => exp.experiencesByCompany)) {
    const experiencesToConsider = isInternalExperiences
      ? companyExperiences.internalExperiences
      : companyExperiences.externalExperiences;

    if (experiencesToConsider.length > 0) {
      const label = groupBy(experiencesToConsider).toString();
      const companyName = experiencesToConsider[0]?.companyName ?? '';
      if (!labels.find(experienceLabel => experienceLabel === label)) labels.push(label);

      groupedExperiences.push({
        label,
        companyName,
        experiences: experiencesToConsider,
      });
    }
  }

  switch (sortOption) {
    case SORT_OPTIONS.HOURS:
    case SORT_OPTIONS.YEAR:
      groupedExperiences.sort((a, b) => {
        return moment(a.experiences[0].startDate).diff(moment(b.experiences[0].startDate));
      });
      labels.sort((a, b) => {
        return parseInt(a) - parseInt(b);
      });
      break;
    case SORT_OPTIONS.NAME:
      groupedExperiences.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
      labels.sort((a, b) => {
        return a.localeCompare(b);
      });
      break;
    default:
      break;
  }
  if (sortOrder === SORT_ORDER.DESCENDING) {
    groupedExperiences.reverse();
    labels.reverse();
  }

  return { labels: labels, groupedExperiences: groupedExperiences };
};

export const getTitleValue = ({
  startDates,
  totalHours,
  firstDate,
  lastDate,
  experienceType,
}: {
  startDates: Date[];
  totalHours: number;
  firstDate: Date;
  lastDate: Date;
  experienceType: EXPERIENCE_TYPE;
}) => {
  if (startDates.length > 0) {
    return experienceType === EXPERIENCE_TYPE.INTERNAL_EXPERIENCES
      ? `${totalHours} heures totales via randstad de ${moment(firstDate).format('YYYY')} à 
  ${moment(lastDate).format('YYYY')}`
      : `expérience externe de ${moment(firstDate).format('YYYY')} à 
    ${moment(lastDate).format('YYYY')}`;
  } else {
    return experienceType === EXPERIENCE_TYPE.INTERNAL_EXPERIENCES
      ? "pas d'expérience via randstad"
      : "pas d'expérience externe";
  }
};
