import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
} from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import { IllusWarning } from 'src/Assets';

import styles from './ResumeGeneratorConfirmationModal.module.scss';
import { Props } from './ResumeGeneratorConfirmationModal.types';

const ResumeGeneratorConfirmationModal = ({ open, setOpen, downloadResume }: Props) => {
  return (
    <Modal size="medium" open={open}>
      <ModalContent title="message d'alerte" header={<IllusWarning />}>
        <div className={styles.content}>
          <p className={styles.text}>
            Vous vous apprêtez à générer une synthèse non anonymisée, ce document doit uniquement
            être destiné à un usage interne au groupe Randstad.
          </p>
        </div>
      </ModalContent>
      <ModalActions side="right">
        <Button.Primary
          onClick={() => {
            setOpen(false);
            downloadResume();
          }}
        >
          valider
        </Button.Primary>
      </ModalActions>
      <ModalActions side="left">
        <Button.Secondary
          onClick={() => {
            setOpen(false);
          }}
        >
          annuler
        </Button.Secondary>
      </ModalActions>
    </Modal>
  );
};

export default ResumeGeneratorConfirmationModal;
