import { TextArea } from '@randstad-lean-mobile-factory/react-form-fields';

import styles from './Description.module.scss';
import { DescriptionProps } from './Description.types';

export const Description = ({ control }: DescriptionProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        que pouvez-vous dire pour mettre en avant ce talent ?
      </div>
      <TextArea
        control={control}
        name="description"
        className={styles.text}
        placeholder={
          'le mot du consultant : en quelques mots, décrivez pourquoi le talent sera parfait pour sa future mission (savoir-faire, savoir-être, motivation, etc.)'
        }
        maxLength={1000}
      />
    </div>
  );
};
