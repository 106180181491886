import { Button, Loader, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import {
  DocumentPDF,
  Folder,
  ThreeDots,
  ThunderLine,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';

import { useOpenOSMResume, useOpenPersonalResume } from 'src/Hooks/Candidates';
import { useIsProductionEnvironment } from 'src/Hooks/Environment/useIsProductionEnvironment';
import { useOpenCandidateFile } from 'src/Hooks/Navigation/useOpenCandidateFile';
import { useOpenCandidateResume } from 'src/Hooks/Navigation/useOpenCandidateResume';

import { CANDIDATE_STATUS_LABEL } from '../CandidateResults.types';

import styles from './ActionsButton.module.scss';
import { Props } from './ActionsButton.types';

const ActionsButton = ({
  candidateId,
  candidateName,
  candidateStatus,
  canBeSelected,
  isActionsOpen,
  setIsActionsOpen,
}: Props) => {
  const { isProd } = useIsProductionEnvironment();
  const { mutate: mutateOSM, isLoading: isLoadingOSM } = useOpenOSMResume();
  const { mutate: mutatePersonal, isLoading: isLoadingPersonal } = useOpenPersonalResume();
  const { openCandidateFile } = useOpenCandidateFile();
  const { openCandidateResume } = useOpenCandidateResume();

  return (
    <PopupMenu
      trigger={
        <Button.Secondary
          className={classnames(styles.actionsButton, {
            [styles.actionsButtonSelected]: isActionsOpen,
          })}
        >
          <ThreeDots className={styles.actionsDots} /> actions
        </Button.Secondary>
      }
      onOpen={() => setIsActionsOpen(!isActionsOpen)}
      onClose={() => setIsActionsOpen(!isActionsOpen)}
      width={242}
      className={styles.actionsMenu}
      position="top left"
      open={isLoadingOSM}
    >
      <PopupMenu.Item
        className={styles.actionsItems}
        text="cv Osmose"
        icon={
          isLoadingOSM ? <Loader heightInRem={0.8} className={styles.loader} /> : <DocumentPDF />
        }
        onClick={() => mutateOSM({ candidateId, candidateName })}
        disabled={candidateStatus === CANDIDATE_STATUS_LABEL['Candidat']}
      />
      <PopupMenu.Item
        className={styles.actionsItems}
        text="cv personnel"
        icon={
          isLoadingPersonal ? (
            <Loader heightInRem={0.8} className={styles.loader} />
          ) : (
            <DocumentPDF />
          )
        }
        onClick={() => mutatePersonal({ candidateId, candidateName })}
      />
      {!isProd && (
        <PopupMenu.Item
          className={styles.actionsItems}
          text="générer la synthèse"
          icon={<ThunderLine />}
          onClick={() => openCandidateResume({ candidateId: candidateId })}
        />
      )}
      {!canBeSelected && (
        <PopupMenu.Item
          className={styles.actionsItems}
          text="dossier recrut'live"
          icon={<Folder />}
          onClick={() => openCandidateFile({ candidateId: candidateId, emitAnalyticsEvent: true })}
        />
      )}
    </PopupMenu>
  );
};

export default ActionsButton;
