import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
} from '@randstad-lean-mobile-factory/react-components-core';
import React, { useCallback } from 'react';

import { IllusWarning } from 'src/Assets';

import styles from './ResumeGeneratorExitModal.module.scss';
import { Props } from './ResumeGeneratorExitModal.types';

const ResumeGeneratorExitModal = ({ close, open, setIsModalOpen }: Props) => {
  const onClose = useCallback(() => {
    setIsModalOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Modal size="medium" open={open} onClose={onClose}>
      <ModalContent title="retour à la recherche" header={<IllusWarning />}>
        <p className={styles.text}>
          Si vous quittez la génération de synthèse, vos modifications seront perdues
        </p>
      </ModalContent>
      <ModalActions side="right">
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>
        <Button.Primary
          onClick={() => {
            close();
          }}
        >
          quitter
        </Button.Primary>
      </ModalActions>
    </Modal>
  );
};

export default ResumeGeneratorExitModal;
