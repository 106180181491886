import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import React from 'react';

import { Success } from 'src/Assets';

import styles from './ResumeGeneratorValidationModal.module.scss';
import { Props } from './ResumeGeneratorValidationModal.types';

const ResumeGeneratorValidationModal = ({ close, open, documentName }: Props) => {
  return (
    <Modal size="medium" open={open} onClose={close}>
      <ModalContent title="message d'information" header={<Success />}>
        <div className={styles.content}>
          <p className={styles.date}>{moment().format('[le ]L[ à ]HH:mm')}</p>
          <p className={styles.text}>
            Votre document a bien été ajouté aux documents Osmose sous le nom {documentName} dans la
            rubrique documents.
          </p>
        </div>
      </ModalContent>
      <ModalActions side="right">
        <Button.Primary onClick={close}>valider</Button.Primary>
      </ModalActions>
    </Modal>
  );
};

export default ResumeGeneratorValidationModal;
